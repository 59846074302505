import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { destroyArticle, getArticles } from '@api/blog'
import { reactive, ref, watch } from '@vue/composition-api'

const cached = []

export default function useList() {
  const loading = ref(false)

  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Status', value: 'status' },
    { text: 'Slug', value: 'slug' },
    { text: 'Title', value: 'title' },
    { text: 'Created At', value: 'created_at' },
    { text: 'Published At', value: 'published_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableTotal = ref(0)
  const tableSelectedData = ref([])

  const statusOptions = [
    {
      title: 'Published',
      value: 'published',
    },
    {
      title: 'Unpublished',
      value: 'unpublished',
    },
    {
      title: 'Draft',
      value: 'draft',
    },
    {
      title: 'All',
      value: null,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )

  // Filter
  const filters = reactive({
    status: null,
  })

  const search = ref(null)

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce((a, key) => ({ ...a, [key]: filters[key] }), {})

  const loadArticles = async () => {
    loading.value = true
    let res

    try {
      const params = {
        ...useTableOptions(options.value),
        ...parseFilters(),
        ...(search.value && { search: search.value }),
      }

      res = await getArticles(params)

      const {
        status,
        data: {
          data: { records, pagination },
        },
      } = res

      if (status === 200) {
        tableList.value = records
        tableTotal.value = pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }

    return res
  }

  const deleteArticle = article =>
    destroyArticle(article.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadArticles()
      })
      .catch(useNotifyErrors)

  const cachable = [options]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    tableSelectedData.value = []

    loadArticles()
    cache()
  })

  return {
    statusOptions,
    options,
    filters,
    search,

    tableList,
    tableColumns,
    tableTotal,
    tableSelectedData,

    loading,
    loadArticles,
    deleteArticle,
  }
}
